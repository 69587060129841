<template>
  <div style="display: flex">
    <div class="role">
      <div v-for="(role, index) in roleList" :key="'role' + index">
        <div :style="getId === role.roleId ? 'background-color: #e1e8f2' : ''" @click="roleInfo(role)" class="roleList" style="">
          {{ role.lable }}
        </div>
      </div>
    </div>
    <div style="width: 50%">
      <CheckboxGroup v-model="addGroup" @on-change="addPersonal">
        <Checkbox class="person" :label="person.userId" v-for="(person, index) in personaList" :key="'person' + index">
          <Avatar :src="person.avatar || require('@assets/defaultAvatar.svg')" style="margin-left: 8px" />
          <span style="margin-left: 8px">{{ person.realName }}</span>
        </Checkbox>
      </CheckboxGroup>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Role',
  props: {
    roleList: {
      type: Array
    }
  },
  watch: {
    roleList(val) {
      this.treeData = val
    }
  },
  data() {
    return {
      addGroup: [],
      realName: [],
      personaList: [],
      getId: 0,
      userId: [],
      defaultProps: {
        children: 'userList',
        label: 'lable'
      },
      treeData: this.roleList
    }
  },
  created() {},
  methods: {
    addPersonal() {
      this.$emit('personnel', this.addGroup)
    },
    roleInfo(data) {
      this.getId = data.roleId
      if (data.userList) {
        this.personaList = data.userList
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../../theme/variables';
::v-deep .el-checkbox__input {
  position: relative;
  right: 6px;
}
::v-deep .el-tree-node__content > .el-tree-node__expand-icon {
  padding: 7px;
  position: relative;
  left: 28px;
}
.role {
  width: 50%;
  border-right: 1px solid #e6e6e6ff;
  height: 380px;
  overflow: auto;
}
.roleList {
  cursor: pointer;
  width: 100%;
  height: 30px;
  line-height: 30px;
  padding-left: 20px;
  &:hover {
    background-color: #ebf0f6ff;
  }
}
.person {
  width: 100%;
  height: 30px;
  line-height: 30px;
  margin-left: 30px;
  padding: 12px 0;
}
</style>
