<template>
  <div style="display: flex;max-height: 698px;overflow: auto">
    <div style="width: 50%;border-right: 1px solid #e6e6e6ff;height: 380px;">
      <el-tree :data="treeData" ref="tree" :props="defaultProps" @node-click="handleClick"> </el-tree>
    </div>
    <div style="width: 50%">
      <CheckboxGroup v-model="addGroup" @on-change="addPersonal">
        <Checkbox class="person" :label="person.userId" v-for="person in depemrntList" :key="person.userId">
          <Avatar :src="person.avatar || require('@assets/defaultAvatar.svg')" style="margin-left: 8px" />
          <span style="margin-left: 8px">{{ person.realName }}</span>
        </Checkbox>
      </CheckboxGroup>
    </div>
  </div>
</template>

<script>
import department from '@api/department'
import courseApi from '@api/course'

export default {
  props: {
    roleList: {
      type: Array
    },
    itGroupId: {
      type: Number,
      required: true
    }
  },
  created() {
    this.getDepartment()
  },
  data() {
    return {
      addGroup: [],
      depemrntList: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      treeData: []
    }
  },
  methods: {
    addPersonal() {
      this.$emit('depement', this.addGroup)
    },
    handleClick(data) {
      this.getPerson(data.departmentId)
    },
    getPerson(data) {
      department.getDepartmentStaff(data).then(res => {
        this.depemrntList = res.res
      })
    },
    getDepartment() {
      department.getDepartmentsTree().then(res => {
        this.treeData = res.res.children
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../../theme/variables';
.role {
  width: 50%;
  border-right: 1px solid #e6e6e6ff;
  height: 380px;
  overflow: auto;
}
.roleList {
  cursor: pointer;
  width: 100%;
  height: 30px;
  line-height: 30px;
  padding-left: 20px;
  &:hover {
    background-color: #ebf0f6ff;
  }
}
.person {
  width: 100%;
  height: 30px;
  line-height: 30px;
  margin-left: 30px;
  padding: 12px 0;
}
::v-deep .el-tree-node__content {
  height: 35px;
}
/* 滚动条 */
::-webkit-scrollbar-thumb:horizontal {
  /*水平滚动条的样式*/
  height: 50px;
  background-color: #999;
  -webkit-border-radius: 4px;
  outline: 2px solid #fff;
  outline-offset: -2px;
  border: 2px solid #fff;
}
::-webkit-scrollbar-track-piece {
  background-color: #fff; /*滚动条的背景颜色*/
  -webkit-border-radius: 0; /*滚动条的圆角宽度*/
}
::-webkit-scrollbar {
  width: 10px; /*滚动条的宽度*/
  height: 8px; /*滚动条的高度*/
}
::-webkit-scrollbar-thumb:vertical {
  /*垂直滚动条的样式*/
  height: 50px;
  background-color: #999;
  -webkit-border-radius: 4px;
  outline: 2px solid #fff;
  outline-offset: -2px;
  border: 2px solid #fff;
}
::-webkit-scrollbar-thumb:hover {
  /*滚动条的hover样式*/
  height: 50px;
  background-color: #9f9f9f;
  -webkit-border-radius: 4px;
}
</style>
