<template>
  <div class="bg-shadow">
    <div class="table-container bg-shadow">
      <Table stripe :columns="tableHeader" :data="courseCard.data" @on-selection-change="deleteItem">
        <template slot-scope="{ row, index }" slot="name">
          <span>{{ row.name }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="description">
          <span class="desc">{{ row.description.length > 10 ? row.description.substr(0, 10) + '...' : row.description }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="creatorName">
          <span>{{ row.creatorName }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="createTime">
          <span>{{ $formatTime(row.createTime, 'yyyy-MM-dd hh:mm') }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="action">
          <svg class="icon" aria-hidden="true" style="cursor: pointer" @click="deleteLesson(row)">
            <use xlink:href="#icon-shanchu"></use>
          </svg>
        </template>
      </Table>
      <div class="page">
        <div style="float: right;margin-top: 20px">
          <Page
            show-total
            show-sizer
            show-elevator
            :total="this.courseCard.total"
            @on-change="changePage"
            @on-page-size-change="changePageSize"
            :current="pageNumber + 1"
          />
        </div>
      </div>
    </div>
    <DeleteModal v-model="deleteClass" :title="`${deleteTitle}`" @on-ok="delLesson">
      <div style="height: 1px"></div>
    </DeleteModal>
  </div>
</template>

<script>
import courseApi from '@api/course'
import DeleteModal from '@components/common/DeleteModal'
export default {
  components: {
    DeleteModal
  },
  name: 'Curriculum',
  props: {
    itGroupId: {
      type: Number,
      required: true
    },
    searchForname: {
      type: String,
      required: true
    }
  },
  watch: {
    itGroupId(data) {
      this.getGroupCourses()
    }
  },
  created() {
    this.getGroupCourses()
  },
  data: function() {
    return {
      groupId: this.itGroupId,
      keyword: '',
      deteList: [],
      deleteTitle: '',
      deleteClass: false,
      elementIds: [],
      courseCard: {
        total: 0,
        page: 0,
        data: []
      },
      data1: [],
      pageNumber: 0,
      pageSize: 10,
      tableHeader: [
        {
          type: 'selection',
          align: 'center',
          width: 60
        },
        {
          title: '课程名称',
          slot: 'name',
          key: 'name',
          sortable: true,
          className: 'name-column',
          ellipsis: true
        },
        {
          title: '描述',
          slot: 'description',
          className: 'description-column',
          ellipsis: true
        },
        {
          title: '创建人',
          slot: 'creatorName',
          className: 'type-column'
        },
        {
          title: '创建时间',
          slot: 'createTime',
          className: 'status-column'
        },
        {
          title: '操作',
          slot: 'action',
          className: 'action-column'
        }
      ]
    }
  },
  methods: {
    changePage(page) {
      this.pageNumber = page - 1
      this.getGroupCourses()
    },
    changePageSize(size) {
      this.pageSize = size
      this.getGroupCourses()
    },
    deleteItem(data) {
      let row = []
      data.map(item => {
        row.push(item.courseId)
      })
      this.deteList = row
      this.$emit('deteList', this.deteList)
    },
    deleteLesson(data) {
      this.elementIds = data.courseId
      this.deleteClass = true
      this.deleteTitle = '确定要删除这门课程吗？'
    },
    delLesson() {
      let groupElementForm = {
        elementIds: [this.elementIds],
        facilityType: 0,
        groupId: this.itGroupId
      }
      courseApi.delGroupElements(groupElementForm).then(res => {
        this.$message.success('删除成功')
        this.deleteClass = false
        this.getGroupCourses()
      })
    },
    getGroupCourses(data) {
      if (this.$route.query.groupId !== undefined) {
        this.groupId = this.$route.query.groupId
      }
      if (data !== undefined) {
        this.keyword = data
      }
      courseApi.getGroupCourses(this.pageNumber, this.pageSize, this.groupId, this.keyword).then(res => {
        this.courseCard.data = res.res.data
        this.courseCard.total = res.res.total
        this.$emit('courseData', this.courseCard.data)
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../../../theme/variables';
</style>
