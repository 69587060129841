<template>
  <div class="course-container">
    <Breadcrumb class="custom-breadcrumb">
      <BreadcrumbItem>课程管理</BreadcrumbItem>
      <BreadcrumbItem>功能组</BreadcrumbItem>
    </Breadcrumb>
    <div style="display: flex;margin-top: 10px">
      <div class="left">
        <div class="top" style="display: flex;justify-content: space-between">
          <div>
            <svg class="icon" aria-hidden="true" style="height: 14px;width: 16px;margin-right: 5px;color: #6a8abeff">
              <use xlink:href="#icon-jiagoufenxiao"></use>
            </svg>
            <span>功能组</span>
          </div>
          <div @click="delFunt">
            <svg class="icon" aria-hidden="true" style="height: 14px;width: 16px;margin-right: 5px;color: #6a8abeff;cursor: pointer">
              <use xlink:href="#icon-shanchu"></use>
            </svg>
          </div>
        </div>
        <div class="tree">
          <el-tree :data="treeData" :props="defaultProps" node-key="id" ref="tree" show-checkbox @check="handleClick" :highlight-current="true">
            <div
              class="custom-tree-node"
              style="width: 100%;display: flex;justify-content: space-between;height: 100%;line-height: 30px"
              :style="showBackgrong === true && node.id === 1 ? 'background-color: #e1e8f2' : ''"
              node-key="data.id"
              slot-scope="{ node, data }"
              @mouseenter="addShowIt(data)"
              @mouseleave="leaveAdd"
            >
              <div @click="handleNodeClick(data)" style="width: 100%;">
                <div>
                  {{ node.label }}
                </div>
              </div>
              <div v-if="addShowId === data.id">
                <div v-if="data.id !== 0">
                  <svg
                    @click=";(isAdd = false), addFun(data)"
                    class="icon"
                    aria-hidden="true"
                    style="height: 14px;width: 16px;margin-right: 5px;color: #B0B0B0"
                  >
                    <use xlink:href="#icon-bianji3"></use>
                  </svg>
                  <svg
                    @click=";(isAdd = true), addFun(data)"
                    class="icon"
                    aria-hidden="true"
                    style="height: 14px;width: 16px;margin-right: 5px;color: #B0B0B0"
                  >
                    <use xlink:href="#icon-xinzeng"></use>
                  </svg>
                  <svg @click="delFun(data)" class="icon" aria-hidden="true" style="height: 14px;width: 16px;margin-right: 5px;color: #B0B0B0">
                    <use xlink:href="#icon-shanchu"></use>
                  </svg>
                </div>
              </div>
            </div>
          </el-tree>
          <div class="addFunction" @click="addpClass">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-xinzeng"></use>
            </svg>
            <span style="margin-left: 6px">新增功能分组</span>
          </div>
        </div>
      </div>
      <div v-if="showRight === false" style="flex: auto;box-shadow:0px 0px 5px rgba(7,27,41,0.1);background-color: white">
        <div style="display: flex;align-items: center;justify-content: center;height: 100%">
          <div>
            <img src="../../../../assets/暂无记录@2x.png" />
            <div style="color: #3A4E64;font-size: 14px;font-weight: 400;margin: 30px 0">
              没有任何内容哦
            </div>
          </div>
        </div>
      </div>
      <div v-if="showRight === true" style="width: 100%">
        <div style="flex: auto">
          <div style="display: flex">
            <div style="width: 100%">
              <div class="bg-shadow func-bar">
                <Tabs v-model="currentTab" @on-click="nation">
                  <TabPane v-if="isShowClass === true" label="课程" name="curriculum"></TabPane>
                  <TabPane v-if="showExam === true" label="考试" name="examination"></TabPane>
                  <TabPane v-if="showPersonnel === true" label="人员" name="personnel"></TabPane>
                </Tabs>
                <div class="search-container">
                  <Input
                    ref="search"
                    v-model="searchForm.name"
                    search
                    placeholder="请输入查找关键字"
                    style="margin-left: auto;width: 250px;margin-right: 20px;"
                    @on-enter="searchClass"
                    @on-focus="
                      searchForm = {
                        name: '',
                        beginTime: '',
                        endTime: '',
                        status: ''
                      }
                    "
                  />
                  <Icon type="ios-apps-outline" size="32" style="margin-right: 20px;cursor: pointer" @click="showCard" v-if="false" />
                  <div v-if="showPerson" style="display: flex">
                    <Button
                      v-if="showButtoncl === true"
                      class="filter-btn btn-shadow"
                      :disabled="isClass === true"
                      type="primary"
                      @click="addCourses"
                    >
                      <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-xinzeng"></use>
                      </svg>
                      添加课程
                    </Button>
                    <div v-if="showAddExam === true">
                      <Button
                        v-if="showButtonex === false"
                        class="filter-btn btn-shadow"
                        :disabled="isClass === false"
                        type="primary"
                        @click="addNewExams"
                      >
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-xinzeng"></use>
                        </svg>
                        添加考试
                      </Button>
                      <Button v-if="showButtonex === true" class="filter-btn btn-shadow" type="primary" @click="addNewExams">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-xinzeng"></use>
                        </svg>
                        添加考试
                      </Button>
                    </div>
                  </div>
                  <div v-if="currentTab === 'personnel'" style="width: 197px;margin-right: 20px">
                    <template>
                      <Select v-model="personList.roleId" placeholder="请选择角色" @on-change="searchRole">
                        <Option v-for="item in roleList" :key="item.roleId" :label="item.roleName" :value="item.roleId"> </Option>
                      </Select>
                    </template>
                  </div>
                  <Button class="filter-btn btn-shadow" @click="addPersons" type="primary" v-if="currentTab === 'personnel'">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-xinzeng"></use>
                    </svg>
                    添加人员
                  </Button>
                  <Button @click="delItem" class="filter-btn btn-shadow" type="primary" v-if="isShowCard">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-shanchu"></use>
                    </svg>
                    删除
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <!--          <transition-group name="fade" mode="out-in">-->
          <Curriculum
            ref="classes"
            :itGroupId="itGroupId"
            :searchForname="searchForm.name"
            v-on:deteList="deteList"
            @courseData="courseData"
            v-if="currentTab === 'curriculum'"
          />
          <Examination ref="exaames" v-on:detexame="detexame" v-if="currentTab === 'examination'" @examId="examId" :itGroupId="itGroupId" />
          <Personnel ref="personal" @delPerson="delPerson" :itGroupId="itGroupId" v-if="currentTab === 'personnel'"></Personnel>
          <!--          </transition-group>-->
        </div>
      </div>
    </div>
    <Modal v-model="addNewExam" title="添加试卷" class="new" width="1510px">
      <div slot="footer"></div>
      <NexAddExam ref="NexAddExam" :itGroupId="itGroupId" @on-ok="addNexam" :itemExamId="itemExamId" @on-cancle="addNewExam = false"></NexAddExam
    ></Modal>
    <Modal v-model="addCourse" title="添加课程" class="new" width="1510px">
      <div slot="footer"></div>
      <NewAddCourse
        ref="NewCourse"
        :itemCourseId="itemCourseId"
        :itGroupId="itGroupId"
        :addCourse="addCourse"
        @on-ok="addNclsss"
        @on-cancle="addCourseCanl"
      ></NewAddCourse>
    </Modal>
    <Modal v-model="addGrouping" :title="isAdd ? '新增功能分组' : '修改功能分组'" width="400" @on-ok="addFunction" @on-cancel="cancle">
      <div>
        <input type="text" v-model="addName" style="width: 100%" />
      </div>
      <div class="addGroup">
        <span>选择添加功能</span>
        <CheckboxGroup v-model="funct" @on-change="addFunt" style="display: grid;margin-top: 12px">
          <Checkbox :label="0">课程</Checkbox>
          <Checkbox :label="1" style="margin: 12px 0">考试</Checkbox>
          <Checkbox :label="2">人员</Checkbox>
        </CheckboxGroup>
      </div>
    </Modal>
    <DeleteModal v-model="deleteFunction" :title="`${deleteTitle}`" :loading="deleteLoading" @on-ok="delFunction">
      <div style="height: 1px"></div>
    </DeleteModal>
    <DeleteModal v-model="deleteLesson" :title="`${delesson}`" :loading="deleteLoading" @on-ok="delLesson">
      <div style="height: 1px"></div>
    </DeleteModal>
    <DeleteModal v-model="deleteExam" :title="`${dlexam}`" :loading="deleteLoading" @on-ok="delExam">
      <div style="height: 1px"></div>
    </DeleteModal>
    <Modal v-model="addPerson" title="添加人员" class="newPerson" :loading="deleteLoading" width="658px" @on-ok="addPersonl">
      <Tabs v-model="PersonTab">
        <TabPane label="角色" name="role" style="border: 1px solid #E6E6E6FF;max-height: 380px;overflow: auto">
          <Role v-if="PersonTab === 'role'" :roleList="roleList" @personnel="personnel"></Role>
        </TabPane>
        <TabPane label="部门" name="department" style="border: 1px solid #E6E6E6FF;min-height: 380px">
          <Department :itGroupId="itGroupId" @depement="depement" v-if="PersonTab === 'department'"></Department>
        </TabPane>
      </Tabs>
    </Modal>
  </div>
</template>
<script>
import courseApi from '@api/course'
import DeleteModal from '@components/common/DeleteModal'
import CourseCard from './CourseCard'
import EditModal from './EditModal'
import userApi from '../../../../api/user'
import JYIcon from '@components/common/JYIcon'
import functionApi from '@api/functionApi'
import NewAddCourse from '@components/manage/course/course/NewAddCourse'
import Role from '@components/manage/course/course/Role'
import Department from '@components/manage/course/course/Department'
import NexAddExam from '@components/manage/course/course/NexAddExam'
import Curriculum from '../course/detail/Curriculum'
import Examination from '../course/detail/Examination'
import Personnel from '../course/detail/Personnel'

export default {
  components: {
    Department,
    Role,
    Personnel,
    NexAddExam,
    NewAddCourse,
    EditModal,
    CourseCard,
    DeleteModal,
    JYIcon,
    Curriculum,
    Examination
  },
  created() {
    this.isAdd = true
    this.showBackgrong = true
    this.isShowClass = false
    this.showExam = false
    this.showPersonnel = false
    if (this.$route.query.addCourse !== undefined) {
      this.dataName = this.$route.query.showName
      if (this.$route.query.addCourse === true) {
        this.addCourse = true
      }
    }
    if (this.$route.query.groupId !== undefined) {
      this.itemgroupid = this.$route.query.groupId
      this.getGroupFacility()
    }
    this.getAllGroup()
    this.getGroupRole()
  },
  data() {
    return {
      isAdd: false,
      showBackgrong: false,
      personList: {
        keyword: '',
        roleId: 0
      },
      addPerson: false,
      showPerson: true,
      itemExamId: [],
      itemCourseId: [],
      dataName: '',
      deleteExam: false,
      getData: null,
      showAddExam: false,
      showButtoncl: true,
      showButtonex: false,
      isShowClass: false,
      deleteLesson: false,
      addNewExam: false,
      elementIds: [],
      delementIds: [],
      showExam: false,
      showPersonnel: false,
      showRight: false,
      checkList: [],
      groupid: 0,
      parentId: 0,
      addName: '',
      addId: [],
      deleteFunction: false,
      addShowId: 0,
      groupId: 0,
      delgroupId: 0,
      itGroupId: 0,
      funct: [],
      addGrouping: false,
      isClass: false,
      currentTab: 'curriculum',
      PersonTab: 'role',
      additem: '',
      isShowOut: false,
      isShow: false,
      addCourse: false,
      cardH: document.body.clientHeight - 204,
      treeData: [],
      defaultProps: {
        children: 'groupTreeVOS',
        label: 'name'
      },
      searchType: '',
      isShowCard: true,
      showManager: false,
      edit: false,
      deleteVisible: false,
      deleteTitle: '',
      dlexam: '',
      delesson: '',
      deleteLoading: false,
      addModal: false,
      loading: false,
      loadingText: '加载中',
      backTop: false,
      tableHeader: {},
      tableData: {},
      courses: {
        data: [],
        total: 0
      },
      editForm: {},
      pageNumber: 0,
      pageSize: 10,
      searchForm: {
        name: '',
        beginTime: '',
        endTime: '',
        status: ''
      },
      searchVisible: false,
      delCourseIds: [],
      selectIds: [],
      searchKeyword: '',
      delStatus: '',
      courseCard: {
        total: 0,
        page: 0,
        data: []
      },
      managerListHeight: window.innerHeight,
      courseId: [],
      managerId: '',
      courseManagerStatus: '',
      managerList: [],
      postId: '',
      itemgroupid: 0,
      roleList: [],
      personeList: [],
      typeList: []
    }
  },
  watch: {
    searchKeyword() {
      this.pageNumber = 0
      this.pageSize = 10
      this.courseCard.page = 0
      this.searchType = ''
    }
  },
  computed: {
    size() {
      let columnNumber = Number.parseInt((window.document.body.clientWidth - 180 - 20 * 2 + 20) / 330)
      let rowNumber = Number.parseInt((window.document.body.clientHeight - 70 - 34 - 152) / 370) + 1
      return rowNumber * columnNumber
    }
  },
  methods: {
    addPersons() {
      this.addPerson = true
    },
    addNewExams() {
      this.addNewExam = true
      this.$refs.NexAddExam.getAllPaperBanks()
      this.$refs.NexAddExam.getQuestionnaire()
    },
    addCourses() {
      this.addCourse = true
      this.$refs.NewCourse.getCourseList()
      this.$refs.NewCourse.getRooter()
    },
    addpClass() {
      this.funct = []
      this.isAdd = true
      this.parentId = 0
      this.addGrouping = true
    },
    addPersonl() {
      let groupElementForm = {
        elementIds: this.personeList,
        facilityType: 2,
        groupId: this.itGroupId
      }
      courseApi.getGroupElements(groupElementForm).then(res => {
        this.$message.success('添加成功')
        this.$refs.personal.getGroupList()
      })
    },
    personnel(data) {
      this.personeList = data
    },
    depement(data) {
      this.personeList = data
    },
    searchRole(data) {
      this.personList.roleId = data
      this.personList.keyword = ''
      this.$refs.personal.getGroupList(this.personList)
    },
    getGroupRole() {
      courseApi.getGroupRole(this.itGroupId).then(res => {
        this.sortTree(res.res)
        this.roleList = res.res
        // this.roleList.unshift({
        //   lable: '所有角色',
        //   roleId: '',
        //   roleName: '所有角色'
        // })
      })
    },
    sortTree(data) {
      data.map(item => {
        item.lable = item.roleName || item.realName
        if (item.userList) {
          this.sortTree(item.userList)
        }
      })
    },
    addCourseCanl() {
      this.addCourse = false
      this.itemCourseId = []
    },
    courseData(courseData) {
      let row = []
      courseData.map(item => {
        row.push(item.courseId)
      })
      this.itemCourseId = row
    },
    examId(examId) {
      this.itemExamId = examId
    },
    searchClass() {
      if (this.currentTab === 'curriculum') {
        this.$refs.classes.getGroupCourses(this.searchForm.name)
      } else if (this.currentTab === 'examination') {
        this.$refs.exaames.getGroupExams(this.searchForm.name)
      } else if (this.currentTab === 'personnel') {
        this.personList.keyword = this.searchForm.name
        this.personList.roleId = 0
        this.$refs.personal.getGroupList(this.personList)
      }
    },
    cancle() {
      this.funct = []
      this.addGrouping = false
      this.addName = ''
    },
    delFunt() {
      if (this.checkList.length === 0) {
        this.$message.error('请选择要删除的功能组')
      } else {
        this.deleteTitle = '确定要删除这些功能组吗？'
        this.deleteFunction = true
      }
    },
    delItem() {
      if (this.delementIds.length === 0) {
        if (this.currentTab === 'curriculum') {
          this.$message.error('请选择要删除的课程')
        } else if (this.currentTab === 'examination') {
          this.$message.error('请选择要删除的考试')
        } else if (this.currentTab === 'personnel') {
          this.$message.error('请选择要删除的人员')
        }
      } else {
        if (this.currentTab === 'curriculum') {
          this.deleteLesson = true
          this.delesson = '确定要删除这些课程吗？'
        } else if (this.currentTab === 'examination') {
          this.deleteExam = true
          this.dlexam = '确定要删除这些考试吗？'
        } else if (this.currentTab === 'personnel') {
          this.deleteLesson = true
          this.delesson = '确定要删除这些人员吗？'
        }
      }
    },
    addNclsss() {
      this.addCourse = false
      this.$refs.classes.getGroupCourses(this.searchForm.name)
    },
    addNexam() {
      this.addNewExam = false
      this.$refs.exaames.getGroupExams(this.searchForm.name)
    },
    delPerson(delPerson) {
      this.delementIds = delPerson
    },
    detexame(detexame) {
      this.delementIds = detexame
    },
    deteList(deteList) {
      this.delementIds = deteList
    },
    delExam() {
      this.deleteLoading = true
      let groupElementForm = {
        elementIds: this.delementIds,
        facilityType: 1,
        groupId: this.itGroupId
      }
      courseApi
        .delGroupElements(groupElementForm)
        .then(res => {
          this.$message.success('删除成功')
          this.deleteExam = false
          this.deleteLoading = false
          this.$refs.exaames.getGroupExams(this.searchForm.name)
        })
        .catch(() => {
          this.deleteLoading = false
        })
    },
    delLesson() {
      this.deleteLoading = true
      if (this.currentTab === 'curriculum') {
        let groupElementForm = {
          elementIds: this.delementIds,
          facilityType: 0,
          groupId: this.itGroupId
        }
        courseApi
          .delGroupElements(groupElementForm)
          .then(res => {
            this.$message.success('删除成功')
            this.deleteLesson = false
            this.deleteLoading = false
            this.$refs.classes.getGroupCourses(this.searchForm.name)
          })
          .catch(() => {
            this.deleteLoading = false
          })
      } else if (this.currentTab === 'personnel') {
        let groupElementForm = {
          elementIds: this.delementIds,
          facilityType: 2,
          groupId: this.itGroupId
        }
        courseApi
          .delGroupElements(groupElementForm)
          .then(res => {
            this.$message.success('删除成功')
            this.deleteLesson = false
            this.deleteLoading = false
            this.$refs.personal.getGroupList()
          })
          .catch(() => {
            this.deleteLoading = false
          })
      }
    },
    handleClick(data) {
      this.checkList = [data.id]
      let row = []
      this.$refs.tree.getCheckedNodes().forEach(item => {
        row.push(item.id)
      })
      this.delgroupId = row
    },
    addFunction() {
      if (this.addName === '' || this.addId.length === 0) {
        this.$message.error('名字或功能不能为空')
      } else {
        if (this.isAdd === true) {
          const GroupForm = {
            name: this.addName,
            parentId: this.parentId,
            typeList: this.addId
          }
          courseApi.getGroupAdd(GroupForm).then(res => {
            this.$message.success('添加成功')
            this.addName = ''
            this.funct = []
            this.getAllGroup()
            this.addGrouping = false
          })
        } else {
          let payload = {
            name: this.addName,
            parentId: this.parentId,
            typeList: this.addId
          }
          courseApi.groupModify(this.itemgroupid, payload).then(res => {
            this.getData.id = res.res
            this.itGroupId = res.res
            this.$message.success('修改成功')
            this.funct = []
            this.isAdd = false
            this.getAllGroup()
            this.handleNodeClick(this.getData)
            this.addName = ''
            this.addGrouping = false
          })
        }
      }
    },
    addFunt(data) {
      this.addId = data
    },
    delFunction() {
      let data = this.treeData
      this.deleteLoading = true
      courseApi
        .getGroupDelete(this.delgroupId)
        .then(res => {
          this.$message.success('删除成功')
          this.deleteFunction = false
          this.showRight = false
          this.deleteLoading = false
          this.spliceById(data)
        })
        .catch(() => {
          this.deleteLoading = false
        })
    },
    spliceById(data) {
      if (this.checkList.length === 0) {
        data.forEach((d, index) => {
          if (d.id === this.delgroupId) {
            data.splice(
              data.findIndex(dataId => dataId.id === this.delgroupId),
              1
            )
          } else if (d.groupTreeVOS) {
            this.spliceById(d.groupTreeVOS)
          }
        })
      } else {
        this.getAllGroup()
      }
    },
    leaveAdd() {
      this.addShowId = 0
    },
    addShowIt(data) {
      this.addShowId = data.id
    },
    getGroupFacility() {
      courseApi.getGroupFacility(this.itemgroupid).then(res => {
        let getType = []
        let rowList = []
        if (res.res.length !== 0) {
          this.showRight = true
          res.res.map(item => {
            getType.push(item)
            rowList.push(item.type)
            this.typeList = rowList
            this.funct = rowList
            this.addId = rowList
            if (getType.length === 1) {
              if (getType[0].type === 0) {
                this.currentTab = 'curriculum'
                this.isShowClass = true
              } else if (getType[0].type === 1) {
                this.showExam = true
                this.showButtoncl = false
                this.showAddExam = true
                this.showButtonex = true
                this.currentTab = 'examination'
              } else {
                this.showPersonnel = true
                this.showButtoncl = false
                this.currentTab = 'personnel'
                this.showAddExam = false
              }
            } else if (getType.length === 2) {
              if (getType[0].type === 0) {
                this.currentTab = 'curriculum'
                this.isShowClass = true
                if (getType[1].type === 1) {
                  this.showExam = true
                  this.showAddExam = true
                  this.showButtonex = false
                } else {
                  this.showPersonnel = true
                  this.showAddExam = false
                }
              } else if (getType[0].type === 1) {
                this.showExam = true
                this.showAddExam = true
                this.currentTab = 'examination'
                this.showPersonnel = true
              }
            } else {
              this.currentTab = 'curriculum'
              this.isShowClass = true
              this.showExam = true
              this.showPersonnel = true
            }
          })
        }
      })
    },
    handleNodeClick(data) {
      this.showBackgrong = false
      this.showPerson = true
      this.showButtonex = false
      this.showPersonnel = false
      this.dataName = data.name
      this.isClass = false
      this.getData = data
      this.showAddExam = false
      this.showButtoncl = true
      this.isShowClass = false
      this.showExam = false
      this.showRight = false
      this.itemgroupid = data.id
      this.getGroupFacility()
    },
    addFun(data) {
      if (this.isAdd === true) {
        this.parentId = data.id
        this.addGrouping = true
        this.funct = []
      } else {
        this.addGrouping = true
        this.itemgroupid = data.id
        this.addName = data.name
        this.parentId = data.parentId
        this.handleNodeClick(data)
      }
    },
    delFun(data) {
      this.deleteTitle = '确定要删除' + ' ' + data.name + ' ' + '吗？'
      this.deleteFunction = true
      this.delgroupId = data.id
      this.parentId = data.parentId
    },
    getAllGroup() {
      courseApi.getAllGroup(this.groupid).then(res => {
        let arr = JSON.stringify(res.res)
        this.treeData = JSON.parse(arr.replace(/name/g, 'name'))
        for (let i = 0; i < res.res.length; i++) {
          this.itemgroupid = res.res[0].id
        }
        if (this.isAdd === true) {
          this.getGroupFacility()
        }
      })
    },
    nation(data) {
      if (data === 'examination') {
        this.showPerson = true
        this.isClass = true
        this.searchForm.name = ''
      } else if (data === 'curriculum') {
        this.showPerson = true
        this.isClass = false
        this.searchForm.name = ''
      } else if (data === 'personnel') {
        this.showPerson = false
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../theme/variables';
.cation {
  color: #3a4e64ff;
  font-size: 12px;
  cursor: pointer;
}
& :hover {
  color: #6a8abeff;
}
.course-container {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  height: 100%;
  width: 100%;
  background-color: @layout-body-background;
  position: relative;
  box-sizing: border-box;

  .func-bar {
    margin-bottom: 10px;
    width: 100%;
    flex: 0 0 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding-left: 20px;
    height: 56px;
    ::v-deep .ivu-tabs-bar {
      border: none !important;
      margin-bottom: 5px;
    }

    .filter-btn {
      margin-right: 20px;
    }

    ::v-deep .search-container {
      display: flex;
      align-items: center;
      .ivu-input {
        border: 0;
        border-bottom: 1px solid #ccc;
        border-radius: 0;
      }
      .ivu-input:focus {
        box-shadow: 0 1px 0 rgba(45, 140, 240, 0.2);
      }

      .advanced-search {
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
  .func {
    margin-bottom: 10px;
    width: 100%;
    flex: 0 0 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding-left: 20px;
    height: 56px;

    .filter-btn {
      margin-right: 20px;
    }

    /deep/ .search-container {
      display: flex;
      align-items: center;
      .ivu-input {
        border: 0;
        border-bottom: 1px solid #ccc;
        border-radius: 0;
      }
      .ivu-input:focus {
        box-shadow: 0 1px 0 rgba(45, 140, 240, 0.2);
      }

      .advanced-search {
        font-size: 14px;
        cursor: pointer;
      }
    }
  }

  .table-container {
    width: 100%;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ::v-deep .flex-table {
      border-bottom: 1px solid #e8eaec;

      .ivu-table-row {
        cursor: pointer;
      }

      .status-container {
        display: flex;
        align-items: center;

        .status {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          margin-right: 4px;
        }
      }

      .operation {
        cursor: pointer;
        color: @primary-color;
        transition: all @default-transition;

        &:hover {
          color: @primary-hover-color;
        }
      }

      .selection-column {
        width: 60px;
      }

      .name-column {
        width: 20%;
      }

      .description-column {
        flex: auto;
        width: 100px;
        overflow: hidden !important;
      }

      .type-column {
        width: 20%;
      }

      .status-column {
        width: 9%;
      }

      .action-column {
        width: 9%;
      }
    }

    .page {
      flex: 0 0 auto;
      padding: 20px;
      font-size: 14px;

      .page-options {
        float: left;
        height: 32px;
        line-height: 32px;
      }
    }
  }
}

::v-deep .form {
  padding: 40px;
  text-align: center;

  .search-btn {
    width: 106px;
  }
}

.scroll-container {
  background-color: @layout-body-background;
  flex: 1 1 auto;
  overflow: auto;

  ::v-deep .ivu-scroll-wrapper {
    height: 100%;

    .ivu-scroll-container {
      height: 100% !important;
      overflow: scroll;

      .ivu-scroll-content {
        /*卡片阴影会被遮挡，故多 2px*/
        padding-left: 2px;
      }
    }
  }

  .course-list {
    display: flex;
    flex-flow: row wrap;
    margin-left: -20px;
    ::v-deep .course-card {
      margin: 0 0 20px 20px;
    }
  }
  .top {
    position: fixed;
    right: 20px;
    bottom: 100px;
    background: rgba(@primary-color, 0.7);
    color: #fff;
    text-align: center;
    border-radius: 2px;
    font-size: 20px;
    cursor: pointer;
    transition: background-color @default-transition;
    width: 40px;
    height: 40px;
    line-height: 40px;

    &:hover {
      background: rgba(@primary-color, 0.9);
    }

    svg {
      transform: rotate(180deg);
    }
  }
}

.manager ::v-deep .ivu-modal {
  text-align: center;
  .ivu-modal-body {
    margin-top: -30px;
  }
}
.managerList {
  height: 30px;
  border-radius: 4px;
  text-align: left;
  padding: 5px 0 0 10px;
  cursor: pointer;
  &:hover {
    background-color: #e4e9efff;
  }
  &:focus {
    background-color: #e4e9efff;
    outline: none;
    font-weight: bold;
  }
}
.left {
  min-width: 300px;
  box-shadow: 0px 0px 5px rgba(7, 27, 41, 0.1);
  margin-right: 20px;
  background-color: #ffffff;
  .top {
    height: 56px;
    background: #e4e9ef;
    font-size: 16px;
    color: #3a4e64;
    font-weight: 400;
    padding: 16px 23px;
    display: flex;
    align-items: center;
  }
  .tree {
    padding: 15px 12px;
    text-align: left;
    height: calc(100vh - 196px);
    overflow: auto;
    ::v-deep .el-checkbox__input {
      position: relative;
      right: 6px;
    }
    ::v-deep .el-tree-node__content > .el-tree-node__expand-icon {
      padding: 7px;
      position: relative;
      left: 28px;
    }
  }
}
.new {
  ::v-deep .ivu-modal-footer {
    display: none;
  }
}
::v-deep .ivu-tree ul {
  font-size: 14px;
  color: #5e6470;
}
.show {
  cursor: pointer;
}
.addIt {
  cursor: pointer;
  font-size: 14px;
  color: #5e6470ff;
}
&:hover {
  color: #6a8abeff;
}
.showTable {
  margin-right: 20px;
  cursor: pointer;
}
::v-deep .ivu-tabs-ink-bar {
  height: 2px;
}
.addFunction {
  margin-top: 12px;
  cursor: pointer;
  color: #3a4e64;
}
& :hover {
  color: #6a8abe;
}
.addGroup {
  border: 1px solid #e4e9ef;
  margin-top: 12px;
  font-size: 14px;
  color: #5e6470;
  padding: 12px;
}
& :hover {
  color: #5e6470;
}
.el-tree {
  background: none;
}
::v-deep .el-tree-node__content:hover {
  background-color: #e4e9ef;
}
::v-deep .el-checkbox__inner:hover {
  border-color: #dcdfe6;
}
.newPerson {
  ::v-deep .ivu-modal .ivu-modal-header {
    padding: 30px 16px 0px 16px;
  }
  ::v-deep .ivu-modal .ivu-modal-body {
    padding: 12px 20px;
  }
  & ::v-deep .ivu-tabs-bar {
    margin-bottom: 0;
  }
}
</style>
