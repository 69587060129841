<template>
  <div class="bg-shadow">
    <div class="table-container bg-shadow">
      <Table stripe :columns="tableHeader" :data="this.courseCard.data" @on-selection-change="showChose">
        <template slot-scope="{ row, index }" slot="name">
          <span>{{ row.name }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="paperBankName">
          <span>{{ row.paperBankName }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="creatorName">
          <span>{{ row.creatorName }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="createdTime">
          <span>{{ $formatTime(row.createdTime, 'yyyy-MM-dd hh:mm') }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="score">
          <span>{{ row.score }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="passScore">
          <span>总分大于 {{ Math.round(row.passScore * 100) }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="action">
          <svg class="icon" aria-hidden="true" style="cursor: pointer" @click="deleteExam(row)">
            <use xlink:href="#icon-shanchu"></use>
          </svg>
        </template>
      </Table>
      <div class="page">
        <div style="float: right;margin-top: 20px">
          <Page
            show-total
            show-sizer
            show-elevator
            :total="this.courseCard.total"
            @on-change="changePage"
            @on-page-size-change="changePageSize"
            :current="pageNumber + 1"
          />
        </div>
      </div>
    </div>
    <DeleteModal v-model="deleteClass" :title="`${deleteTitle}`" @on-ok="delExame">
      <div style="height: 1px"></div>
    </DeleteModal>
  </div>
</template>

<script>
import DeleteModal from '@components/common/DeleteModal'
import courseApi from '@api/course'
export default {
  components: {
    DeleteModal
  },
  props: {
    itGroupId: {
      type: Number,
      required: true
    }
  },
  watch: {
    itGroupId(data) {
      this.getGroupExams()
    }
  },
  created() {
    this.getGroupExams()
  },
  data: function() {
    return {
      examId: [],
      detexame: [],
      deleteClass: false,
      deleteTitle: '',
      courseCard: {
        total: 0,
        page: 0,
        data: []
      },
      data1: [],
      elementIds: [],
      pageNumber: 0,
      pageSize: 10,
      tableHeader: [
        {
          type: 'selection',
          align: 'center',
          className: 'selection-column',
          width: 60
        },
        {
          title: '试卷名称',
          slot: 'name',
          key: 'name',
          sortable: true,
          className: 'name-column',
          ellipsis: true
        },
        {
          title: '试卷库名称',
          slot: 'paperBankName',
          className: 'description-column',
          ellipsis: true,
          width: 200
        },
        {
          title: '创建人',
          slot: 'creatorName',
          className: 'type-column'
        },
        {
          title: '创建时间',
          slot: 'createdTime',
          className: 'status-column'
        },
        {
          title: '总分',
          slot: 'score',
          className: 'status-column'
        },
        {
          title: '通过条件',
          slot: 'passScore',
          className: 'status-column'
        },
        {
          title: '操作',
          slot: 'action',
          className: 'action-column'
        }
      ]
    }
  },
  methods: {
    delExame() {
      let groupElementForm = {
        elementIds: [this.elementIds],
        facilityType: 1,
        groupId: this.itGroupId
      }
      courseApi.delGroupElements(groupElementForm).then(res => {
        this.$message.success('删除成功')
        this.deleteClass = false
        this.getGroupExams()
      })
    },
    deleteExam(data) {
      this.elementIds = data.examId
      this.deleteClass = true
      this.deleteTitle = '确定要删除这门考试吗？'
    },
    changePage(page) {
      this.pageNumber = page - 1
      this.getGroupExams()
    },
    changePageSize(size) {
      this.pageSize = size
      this.getGroupExams()
    },
    showChose(data) {
      let row = []
      data.map(item => {
        row.push(item.examId)
      })
      this.detexame = row
      this.$emit('detexame', this.detexame)
    },
    getGroupExams(data) {
      let keyword = ''
      if (data !== undefined) {
        keyword = data
      }
      courseApi.getGroupExams(this.pageNumber, this.pageSize, this.itGroupId, keyword).then(res => {
        this.courseCard.data = res.res.data
        this.courseCard.total = res.res.total
        res.res.data.map(item => {
          this.examId.push(item.examId)
          this.$emit('examId', this.examId)
        })
      })
    }
  }
}
</script>

<style scoped></style>
