<template>
  <div class="bg-shadow">
    <div class="table-container bg-shadow">
      <Table stripe :columns="tableHeader" :data="courseCard.data" @on-selection-change="deleteItem">
        <template slot-scope="{ row, index }" slot="realName">
          <div style="display: flex">
            <Avatar :src="row.avatar || require('@assets/defaultAvatar.svg')" />
            <!--            <img style="height: 30px;width: 30px;border-radius: 15px" :src="row.avatar" alt="">-->
            <div style="margin-left: 12px;line-height: 30px">
              {{ row.realName }}
            </div>
          </div>
        </template>
        <template slot-scope="{ row, index }" slot="roleName">
          <span class="desc">{{ row.roles.map(role => role.roleName).join('、') }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="departmentName">
          <span>{{ row.departmentName }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="email">
          <span>{{ row.email }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="phone">
          <span>{{ row.phone }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="action">
          <svg class="icon" aria-hidden="true" @click="delPerson(row)" style="cursor: pointer">
            <use xlink:href="#icon-shanchu"></use>
          </svg>
        </template>
      </Table>
      <div class="page">
        <div style="float: right;margin-top: 20px">
          <Page
            show-total
            show-sizer
            show-elevator
            @on-change="changePage"
            @on-page-size-change="changePageSize"
            :total="this.courseCard.total"
            :current="pageNumber + 1"
          />
        </div>
      </div>
    </div>
    <DeleteModal v-model="deletePerson" :title="`${deleteTitle}`" @on-ok="delLesson">
      <div style="height: 1px"></div>
    </DeleteModal>
  </div>
</template>

<script>
import courseApi from '@api/course'
import DeleteModal from '@components/common/DeleteModal'
export default {
  components: {
    DeleteModal
  },
  name: 'Curriculum',
  props: {
    itGroupId: {
      type: Number,
      required: true
    }
  },
  //   searchForname: {
  //     type: String,
  //     required: true
  //   }
  // },
  created() {
    this.getGroupList()
  },
  data: function() {
    return {
      deletePerson: false,
      groupId: this.itGroupId,
      keyword: '',
      deteList: [],
      deleteTitle: '',
      deleteClass: false,
      elementIds: [],
      courseCard: {
        total: 0,
        page: 0,
        data: []
      },
      pageNumber: 0,
      pageSize: 10,
      tableHeader: [
        {
          type: 'selection',
          align: 'center',
          width: 60
        },
        {
          title: '名称',
          slot: 'realName',
          key: 'realName',
          sortable: true,
          ellipsis: true
        },
        {
          title: '角色',
          slot: 'roleName',
          ellipsis: true
        },
        {
          title: '管理部门',
          slot: 'departmentName'
        },
        {
          title: '邮箱',
          slot: 'email'
        },
        {
          title: '手机',
          slot: 'phone'
        },
        {
          title: '操作',
          slot: 'action',
          className: 'action-column'
        }
      ]
    }
  },
  methods: {
    delPerson(data) {
      let person = data.realName
      this.elementIds = data.userId
      this.deletePerson = true
      this.deleteTitle = '确定要删除' + person + '吗？'
    },
    delLesson() {
      let groupElementForm = {
        elementIds: [this.elementIds],
        facilityType: 2,
        groupId: this.itGroupId
      }
      courseApi.delGroupElements(groupElementForm).then(res => {
        this.$message.success('删除成功')
        this.deletePerson = false
        this.getGroupList()
      })
    },
    deleteItem(data) {
      let row = []
      data.map(item => {
        row.push(item.userId)
      })
      this.deteList = row
      this.$emit('delPerson', this.deteList)
    },
    getGroupList(data) {
      let searchGroupUserDTO = {}
      if (data !== undefined) {
        searchGroupUserDTO = {
          keyword: data.keyword,
          roleId: data.roleId
        }
      }
      courseApi.getGroupMembers(this.pageNumber, this.pageSize, this.itGroupId, searchGroupUserDTO).then(res => {
        this.courseCard.data = res.res.data
        this.courseCard.total = res.res.total
      })
    },
    changePage(page) {
      this.pageNumber = page - 1
      this.getGroupList()
    },
    changePageSize(size) {
      this.pageSize = size
      this.getGroupList()
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../../../theme/variables';
</style>
